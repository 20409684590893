"use client";
import { useState, useEffect } from "react";
import Image from "next/image";
import { Button } from "flowbite-react";

const Goalsolution = () => {
  return (
    <div>
      <section className="bg-white py-6 md:py-16 lg:py-24">
        <div className="max-w-screen-xl mx-5 lg:mx-auto">
          <div className="text-center mb-6 md:mb-10 lg:mb-20">
            <h2 className="font-extrabold">We Invest</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between mb-6 md:mb-12">
            <div className="order-1 md:order-2">
              <Image
                src="/images/startup-img.jpg"
                className=" object-cover rounded-xl lg:rounded-3xl"
                alt="Lead Investor"
                width={620}
                height={415}
              />
            </div>
            <div className="order-2 md:order-1">
              <h6 className="text-lime-600 font-bold">Innovate and Prosper</h6>
              <h3 className="font-bold mt-4">Startup Investments</h3>
              <p>
                Invest in the future by providing financial support to emerging
                businesses. UJS gives investors the opportunity to gain access
                to high-potential early-stage firms, giving them the chance to
                be a part of the next major success story by providing them with
                strategic assistance and financial support.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between mb-6 md:mb-12">
            <div className="">
              <Image
                src="/images/Non-convertible-debentures.jpg"
                className=" object-cover rounded-xl lg:rounded-3xl"
                alt=" Non-Convertible Debentures (NCD)"
                width={620}
                height={415}
              />
            </div>
            <div className="">
              <h6 className="text-lime-600 font-bold">
                Stable, Secure Returns
              </h6>
              <h3 className="font-bold mt-4">
                Non-Convertible Debentures (NCD)
              </h3>
              <p>
                Rather to investing in convertible debentures, you could
                consider fixed income instruments because they offer better
                interest rates. Investors that are risk conservative and are
                looking for regular earnings without equity conversion rights
                may find that NCDs offered by UJS are a secure solution.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between mb-6 md:mb-12">
            <div className="order-1 md:order-2">
              <Image
                src="/images/aif-img.jpg"
                className="object-cover rounded-xl lg:rounded-3xl"
                alt="Alternative Investment Funds (AIF)"
                width={620}
                height={415}
              />
            </div>
            <div className="order-2 md:order-1">
              <h6 className="text-lime-600 font-bold">Diversify Distinctly</h6>
              <h3 className="font-bold mt-4">
                Alternative Investment Funds (AIF)
              </h3>
              <p>
                Through the use of AIFs, investigate non-traditional investment
                options. Providing sophisticated investors with diversified
                portfolio options outside of the mainstream markets, our funds
                concentrate on private equity, real estate, and hedge funds as
                their primary areas of investment portfolio management.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between mb-6 md:mb-12">
            <div>
              <Image
                src="/images/real-estate.jpg"
                className=" object-cover rounded-xl lg:rounded-3xl"
                alt="Real Estate Investments"
                width={620}
                height={415}
              />
            </div>
            <div>
              <h6 className="text-lime-600 font-bold">
                Property Powers Profit
              </h6>
              <h3 className="font-bold mt-4">Real Estate Investments</h3>
              <p>
                Investing in real estate is a great way to diversify your
                portfolio. Our investments in real estate include residential,
                commercial, and industrial properties, and they offer the
                possibility of both capital appreciation and rental income.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between mb-6 md:mb-12">
            <div className="md:order-2">
              <Image
                src="/images/equity-img.jpg"
                className=" object-cover rounded-xl lg:rounded-3xl"
                alt="Equity Investments"
                width={620}
                height={415}
              />
            </div>
            <div className="md:order-1">
              <h6 className="text-lime-600 font-bold">Own and Earn</h6>
              <h3 className="font-bold mt-4">Equity Investments</h3>
              <p>
                Acquire ownership stakes in well-established businesses. The
                opportunity to participate in the financial achievements of top
                companies across a variety of industries is made available to
                you through equity investing with UJS. This investment provides
                you with the possibility of considerable profits through capital
                gains and dividends.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between">
            <div>
              <Image
                src="/images/bill-of-exchange.jpg"
                className=" object-cover rounded-xl lg:rounded-3xl"
                alt="Bills of Exchange (BOE)"
                width={620}
                height={415}
              />
            </div>
            <div>
              <h6 className="text-lime-600 font-bold">Liquidity with Trust</h6>
              <h3 className="font-bold mt-4">Bills of Exchange (BOE)</h3>
              <p>
                Short-term investments that are safe and have a high liquidity
                level. Bank obligations (BOEs) are financial instruments that
                are provided by a debtor to a creditor, with the promise of
                paying a certain amount at a later date. BOEs are frequently
                utilized for trade financing and meeting the requirements of
                working capital.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between mb-6 md:mb-12">
            <div className="md:order-2">
              <Image
                src="/images/mutual-funds.jpg"
                className=" object-cover rounded-xl lg:rounded-3xl"
                alt="Mutual Funds"
                width={620}
                height={415}
              />
            </div>
            <div className="md:order-1">
              <h6 className="text-lime-600 font-bold">Managed Wealth Growth</h6>
              <h3 className="font-bold mt-4">Mutual Funds</h3>
              <p>
                Enjoy the benefits of investing funds that are handled by
                professionals. A diverse portfolio of stocks, bonds, or other
                securities is purchased by UJS Mutual Funds, which pool the
                money from various investors in order to purchase the portfolio.
                This provides investors with balanced risk and easier
                management.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 lg:gap-10 items-center justify-between">
            <div>
              <Image
                src="/images/movie-Investments.jpg"
                className=" object-cover rounded-xl lg:rounded-3xl"
                alt="Films & Studio"
                width={620}
                height={415}
              />
            </div>
            <div>
              <h6 className="text-lime-600 font-bold">
                Invest in Entertainment
              </h6>
              <h3 className="font-bold mt-4">Films & Studio</h3>
              <p>
                Participate in the entertainment business by providing financial
                support to films. By broadening your investment portfolio with
                creative enterprises, you may vary your portfolio by investing
                with UJS in movie projects that have prospective returns based
                on how well they perform at the box office and distribution
                rights.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Goalsolution;
